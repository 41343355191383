import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import DotDotDot from '../../../common/components/dotdotdot';
import Link from '../../../common/components/link/internal-link';
import { HorizontalSeparator } from '../../../common/components/separator';
import ContentExcerpt from '../../../common/components/content-excerpt';
import PostFooter from '../../../common/components/post-footer';
import withIsFeedDesignEnabled from '../../../common/hoc/with-is-feed-design-enabled';
import styles from './recent-posts-list-item.scss';

const RecentPostsListItemWithoutCover = ({
  post,
  postLink,
  onLikeClick,
  type,
  titleFontClassName,
  contentFontClassName,
  contentFontClassNameWithStyle,
  isMetadataFooterVisible,
  applyFeedDesign,
  getPostClassName,
}) => {
  const titleClassName = classNames(styles.titleWrapper, 'blog-post-title-color');
  const titleContainerClassName = classNames(!applyFeedDesign && titleFontClassName, getPostClassName('title-font'));
  const containerClassName = classNames(
    styles.main,
    styles.withoutCover,
    !applyFeedDesign && contentFontClassName,
    getPostClassName('description-font'),
  );
  const contentClassName = classNames(
    !applyFeedDesign && contentFontClassNameWithStyle,
    getPostClassName('description-style-font'),
  );

  const renderTitle = (
    <div className={titleContainerClassName} data-hook="recent-post__title">
      <h4 className={titleClassName}>
        <Link className={styles.title} to={postLink}>
          <DotDotDot clamp={3}>{post.title}</DotDotDot>
        </Link>
      </h4>
    </div>
  );

  const renderDescription = (
    <div className={contentClassName} data-hook="recent-post__description">
      <div className={styles.content}>
        <ContentExcerpt type={type} content={post.content} excerpt={post.excerpt} lineCount={4} maxLength={200} />
      </div>
    </div>
  );

  const renderFooter = (
    <div className={styles.footerWrapper}>
      <HorizontalSeparator className={styles.separator} />
      <div className={styles.footer}>
        <PostFooter post={post} onLikeClick={onLikeClick} displayIcons={true} />
      </div>
    </div>
  );

  return (
    <div className={containerClassName}>
      {renderTitle}
      {renderDescription}
      {isMetadataFooterVisible && renderFooter}
    </div>
  );
};

RecentPostsListItemWithoutCover.propTypes = {
  contentFontClassName: PropTypes.string.isRequired,
  getPostClassName: PropTypes.func.isRequired,
  applyFeedDesign: PropTypes.bool.isRequired,
  isMetadataFooterVisible: PropTypes.bool.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  postLink: PropTypes.string.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  contentFontClassNameWithStyle: PropTypes.string.isRequired,
};

export default flowRight(withIsFeedDesignEnabled)(RecentPostsListItemWithoutCover);
